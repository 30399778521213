<template>
    <div>
        <page-header data-cy="user_index_title" btnNew="ACTIONS.NEW" pageTitle="USERS.INDEX_TITLE"
                     filter @filter="filtering" @newType="setRegisterModal"/>
        <div class="card" v-if="items && !items.validations">
            <div v-for="(iten, key) in items.data" :key="iten.id" :class="key != 0 ? 'border-top2': ''">
                <div class="row gutters space-between" @click="open(iten)">
                    <div data-cy="user_index_name" class="list-primary hide-text">{{ iten.name }}</div>
                    <div class="color-grey status-active d-flex flex-column" style="padding-right: 1.5rem">
                        <div class="d-flex justify-content-end mb-2" @click.stop.prevent="copyLink(iten.id)">
                            <button class="btn btn-primary">Link Cadastro de Cliente</button>
                        </div>
                        <div class="d-flex flex-row">
                            <div class="custom-control custom-switch mr-2 align-self-center">
                                <input data-cy="user_index_disable" type="checkbox" class="custom-control-input"
                                       :id="'active'+iten.id"
                                       v-model="iten.active">
                                <label data-cy="user_index_label" class="custom-control-label"
                                       @click.stop.prevent="setCompanyModal(iten, key)"
                                       :for="'active'+iten.id">{{ t('ALLOTMENT.ACTIVE') }}</label>
                            </div>
                            <button class="btn btn-primary"
                                    @click.stop.prevent="setModalEditUser(iten)">Editar Dados Pessoais
                            </button>
                        </div>
                    </div>
                </div>
                <div class="row gutters space-between" id="desdobrar" @click="open(iten)">
                    <div class="col-xl-2 col-lg-4 col-md-4 col-sm-4 col-12">
                        <div class="color-grey hide-text icon-email"
                             style="padding-left: 1.5rem; display: inline-block">
                            <div class="color-grey hide-text" style="padding-left: 1.5rem;  display: inline-block">
                                {{ iten.email }}
                            </div>
                        </div>
                        <div v-if="iten.phones.length > 0" class="color-grey hide-text  icon-phone-call"
                             style="padding-left: 1.5rem">
                            <div class="color-grey hide-text" style="padding-left: 1.5rem; display: inline-block"
                                 v-for="(phone) in iten.phones" :key="phone.id">
                                {{ phone.number }}
                            </div>
                        </div>
                    </div>

                    <div class="col-xl-7 col-lg-5 col-md-4 col-sm-4" v-if="!widthPhone()">
                        <div class="color-grey">
                            <a class="button-center">
                                <i data-cy="user_index_show" class="icon-keyboard_arrow_down pointer"
                                   v-if="!iten.open"></i>
                                <i class="icon-keyboard_arrow_up pointer" v-if="iten.open"></i>
                            </a>
                        </div>
                    </div>

                    <div class="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-12" :style="paddingGroup()"
                         @click.stop.prevent="">
                        <Select2
                                :settings="{ width: '100%',
                     placeholder: t('GENERAL.MSG.SELECT_ONE', {article: 'um', name: t('GENERAL.GRUP')})}"
                                :options="profiles" :id="'profile'+iten.id"
                                v-model="iten.group_id" :name="'profile'+iten.id" data-cy="user_index_group"
                                @select="updateUserCompanhie(iten, false, true, )"
                        />
                    </div>
                    <div class="col-12" v-if="widthPhone()">
                        <div class="color-grey">
                            <a class="button-center">
                                <i data-cy="user_index_show" class="icon-keyboard_arrow_down pointer"
                                   v-if="!iten.open"></i>
                                <i class="icon-keyboard_arrow_up pointer" v-if="iten.open"></i>
                            </a>
                        </div>
                    </div>
                </div>

                <transition name="fade">
                    <div v-show="iten.open">
                        <div class="table-settings more-info border-top2 loading-box" v-if="iten.loading">
                            <div class="row gutters mt-2 mb-3">
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                    <div class="text-center">
                                        <div class="spinner-border" role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="table-settings more-info border-top2 loading-box"
                             v-if="!iten.loading && iten.dontHaveAllotment">
                            <div class="row gutters mt-2 mb-3">
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                    <div class="text-center">
                                        {{
                                            t('GENERAL.MSG.NO_REGISTER', {
                                                name: t('ALLOTMENT.INDEX_TITLE'),
                                                os: t('os')
                                            })
                                        }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="!iten.loading && !iten.dontHaveAllotment">
                            <div v-for="(userAllotment, allotmentKey) in iten.userAllotments" :key="userAllotment.id">

                                <div class="table-settings more-info border-top2"
                                     :class="allotmentKey == 0 ? 'first-box': 'second-box'">
                                    <div class="row gutters align-items font-seven">
                                        <div class="col-xl-2 col-lg-3 col-md-3 col-sm-3 col-6"
                                             v-if="userAllotment.allotment_id">
                                            <div class="custom-control custom-switch">
                                                <input data-cy="user_index_active_checkbox" type="checkbox"
                                                       class="custom-control-input"
                                                       :id="'active'+userAllotment.id" v-model="userAllotment.active"
                                                       @change="updateUserAllotment(userAllotment, iten.id, true)"
                                                       :disabled="!iten.active">
                                                <label class="custom-control-label" :for="'active'+userAllotment.id">{{
                                                        t('ALLOTMENT.ACTIVE')
                                                    }}</label>
                                            </div>
                                            <div class="custom-control custom-switch pt-1" v-if="userAllotment.active">
                                                <input data-cy="user_index_active_checkbox" type="checkbox"
                                                       class="custom-control-input"
                                                       :id="'notify'+userAllotment.id" v-model="userAllotment.notify"
                                                       @change="onOffNotificatonsUserAllotment(userAllotment, iten.id)"
                                                       :disabled="!userAllotment.active">
                                                <label class="custom-control-label" :for="'notify'+userAllotment.id">{{
                                                        t('ALLOTMENT.NOTIFY')
                                                    }}</label>
                                            </div>
                                            <div class="custom-control custom-switch pt-1"
                                                 v-if="userAllotment.active && iten.group_id === 2"
                                            >
                                                <input type="checkbox"
                                                       class="custom-control-input"
                                                       :id="'isgestor'+userAllotment.id"
                                                       v-model="userAllotment.is_gestor"
                                                       @change="onOffNotificatonsUserAllotment(userAllotment, iten.id)"
                                                       :disabled="!userAllotment.active">
                                                <label class="custom-control-label" :for="'isgestor'+userAllotment.id">{{
                                                        t('ALLOTMENT.GESTOR')
                                                    }}</label>
                                            </div>
                                        </div>
                                        <div
                                                :class="userAllotment.allotment_id ?
                                                'col-xl-7 col-lg-6 col-md-6 col-sm-6 col-6' :
                                                'col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 ptb'">
                                            <p class="color-black" style="padding-bottom: 0.5rem;">{{
                                                    userAllotment.name
                                                }}</p>
                                        </div>
                                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12"
                                             v-if="userAllotment.allotment_id">
                                            <div class="form-group">
                                                <label class="color-grey font-seven"
                                                       for="maxReserves">{{ t('USERS.REAL_ESTATE_AGENCY') }}</label>
                                                <select2
                                                        :placeholder="userAllotment.real_estate_agency_name ? userAllotment.real_estate_agency_name : 'Selecione'"
                                                        :settings="ajaxPerson" :id="`${iten.id}${userAllotment.id}`"
                                                        @select="updateUserAllotment(userAllotment, iten.id)"
                                                        v-model="userAllotment.real_estate_agency_id"
                                                ></select2>
                                            </div>

                                            <div class="form-group">
                                                <label class="color-grey font-seven"
                                                       for="maxReserves">{{ t('GENERAL.QUANTITY_MAX_RESERVE') }}</label>
                                                <input data-cy="user_index_reserve_update" type="text" id="maxReserves"
                                                       name="maxReserves"
                                                       v-model="userAllotment.max_number_reserves"
                                                       class="form-control"
                                                       :placeholder="t('GENERAL.QUANTITY_MAX_RESERVE')"
                                                       @change="updateUserAllotment(userAllotment, iten.id)"
                                                       :disabled="!userAllotment.active || !iten.active">
                                            </div>

                                            <div class="form-group"
                                                 v-if="configBrokerageType(userAllotment, userAllotment.brokerage_is_percentage)">
                                                <label for="brokerage">{{ t('ALLOTMENT.BROKERAGE_VALUE') }}: </label>
                                                <div class="input-group">
                                                    <input class="form-control" type="text" id="brokerage"
                                                           name="brokerage"
                                                           v-model.lazy="userAllotment.brokerage_value"
                                                           @focusout="updateUserAllotment({...userAllotment}, iten.id)"
                                                           v-money3="this.configBrokerage[userAllotment.id]">
                                                    <div class="input-group-prepend">
                                                        <button class="btn btn-outline-secondary dropdown-toggle"
                                                                type="button"
                                                                data-toggle="dropdown" aria-haspopup="true"
                                                                aria-expanded="false">
                                                            {{ t('GENERAL.TYPE') }}
                                                        </button>
                                                        <div class="dropdown-menu dropdown-menu-right">
                                                            <a class="dropdown-item bg-hover-white"
                                                               @click="resetConfigBrokerage(userAllotment,false)">{{
                                                                    t('GENERAL.REAIS')
                                                                }}</a>
                                                            <a class="dropdown-item bg-hover-white"
                                                               @click="resetConfigBrokerage(userAllotment,true)">{{
                                                                    t('GENERAL.PERCENTAGE')
                                                                }}</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-xl-7 col-lg-7 col-md-7 col-sm-7 col-7 text-right"
                                             v-if="!userAllotment.allotment_id">
                                            <div class="custom-control custom-switch">
                                                <input type="checkbox" class="custom-control-input"
                                                       :id="'active'+userAllotment.id"
                                                       v-model="userAllotment.active" :disabled="!iten.active"
                                                       @change="setAddUserAllotmentModal(userAllotment, iten, iten.group_id, key, allotmentKey)">
                                                <label class="custom-control-label"
                                                       :for="'active'+userAllotment.id">{{
                                                        t('USERS.GRANT_ACCESS')
                                                    }}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </transition>
            </div>
        </div>
        <div class="card" v-if="items && items.validations">
            <div class="card-header"></div>
            <div class="card-body pt-0">
                <div class="text-center">
                    <h5>{{ t('GENERAL.MSG.NONE_USER_FOUND') }}</h5>
                </div>
            </div>
        </div>

        <pagination-component v-if="items && items.data && items.data[0]" :items="items" :to="'/usuario'"
                              @changePage="index(filters, $event)"></pagination-component>
        <input type="hidden" name="addUserModal" id="addUserModal" data-target="#add" data-toggle="modal">
        <modal :titleModal="modalTitle" :modal-xl="true" v-if="companies && userCreate && hasAllotment"
               @close="userCreate = null">
            <template v-slot:title>{{ t(modalTitle) }}</template>
            <create-users :companiesIndex="companies" :profilesIndex="profiles" :userIndex="userCreate"
                          @create="index(filters)">
            </create-users>
        </modal>
        <modal :titleModal="modalTitle" modal-large v-if="modalEdit" @close="closeModalEdit">
            <template v-slot:title>{{ t(modalTitle) }}</template>
            <editUser-component :item-index="userEdit" @updated="index(filters)"></editUser-component>
        </modal>
        <modal :titleModal="modalTitle" modal-large v-if="wichModal === 1">
            <template v-slot:title>{{ t(modalTitle) }}</template>
            <div class="modal-body">
                <div class="row gutters">
                    <div class="col-4">
                        <div class="form-group">
                            <label for="filter_name">Nome</label>
                            <input type="text" class="form-control" v-model="filters.name" id="filter_name"
                                   placeholder="Nome">
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="form-group">
                            <label>Tipo Usuario</label>
                            <select2 placeholder="Selecione" :settings="{width: '100%'}" v-model="filters.typeUser"
                                     :options="[
                                    {id:'selecione', text:'selecione'}
                                   ,{id:'adm', text: 'Administradores'},
                                    {id:'corretores', text: 'Corretores'}]"
                            ></select2>
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="form-group">
                            <label for="lter_email">E-mail</label>
                            <input type="text" class="form-control" id="lter_email" v-model="filters.email"
                                   placeholder="E-mail">
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="form-group">
                            <label for="filter_taxpayer">CPF/CNPJ</label>
                            <input type="text" class="form-control" id="filter_taxpayer" v-model="filters.taxpayer"
                                   placeholder="CPF/CNPJ">
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="form-group">
                            <label for="filter_phone">Telefone</label>
                            <input type="text" class="form-control" v-model="filters.phone" id="filter_phone"
                                   placeholder="Telefone">
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="form-group">
                            <label for="filter_creci">CRECI</label>
                            <input type="text" class="form-control" id="filter_creci" v-model="filters.creci"
                                   placeholder="Creci">
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button class="btn btn-secondary" @click="closeFilter">Limpar</button>
                <button class="btn btn-primary" @click="this.filterIndex()">Filtrar</button>
            </div>
        </modal>
        <div class="modal fade" id="add" role="dialog" aria-labelledby="staticBackdropLabel" aria-hidden="true"
             data-backdrop="static" style="position: fixed;">
            <button type="button" class="closeX" id="closeModal" data-dismiss="modal" aria-label="Close"
                    hidden></button>
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div data-cy="user_update_title" class="modal-header hearder-custom">
                        <h4 class="modal-title title-size" v-if="modalTitle">{{ t(modalTitle) }}</h4>
                        <button type="button" class="close close-custom" id="close" data-dismiss="modal"
                                aria-label="Close"
                                @click="closeModal">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">

                        <div class="row gutters custom-margin">
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <div class="form-group" v-if="allotment">
                                    <label>{{ t('LOTS.ALLOTMENT') }}: </label>
                                    <label class="ml-1 color-grey">{{ allotment }}</label>
                                </div>
                                <div class="form-group" v-if="!allotment">
                                    <label>{{ t('NAV.COMPANY') }}: </label>
                                    <label class="ml-1 color-grey">{{ companyName }}</label>
                                </div>
                            </div>
                        </div>
                        <div class="row gutters">
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <div class="form-group" v-if="name">
                                    <label>{{ t('RESERVE.REALTOR') }}: </label>
                                    <label class="ml-1 color-grey">{{ name }}</label>
                                </div>
                            </div>
                        </div>

                        <div class="right">
                            <button id="close_modal" type="button" class="btn btn-secondary  mr-1 width-buttons"
                                    data-dismiss="modal"
                                    @click="closeModal">{{ t('ACTIONS.CLOSE') }}
                            </button>
                            <button type="button" class="btn btn-primary mr-1 width-buttons" @click="addUserAllotment"
                                    v-if="allotment">{{ t('ACTIONS.CONFIRM') }}
                            </button>
                            <button data-cy="user_index_confirm_update" type="button"
                                    class="btn btn-primary mr-1 width-buttons"
                                    @click="updateUserCompanhie(this.userCompanyGroup, true)" v-if="!allotment">
                                {{ t('ACTIONS.CONFIRM') }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import {useI18n} from "vue-i18n";
import User from '@/services/user';
import Group from '@/services/Group';
import Modal from "@/components/modal";
import {useToast} from "vue-toastification";
import Select2 from 'vue3-select2-component';
import Allotments from '@/services/Allotments';
import Validate from '@/components/validate.vue';
import Permission from '@/components/permission.vue';
import createUsers from "./createUsersComponent.vue";
import UserCompany from '@/services/UserCompanies.js';
import UserAllotment from '@/services/UserAllotment.js';
import pageHeader from "@/components/layouts/pageHeader";
import PaginationComponent from "@/components/layouts/PaginationComponent";
import editUserComponent from './editUserComponent';
import {Money3Directive} from 'v-money3';

export default {
    name: 'indexUser',
    mixins: [Validate, Permission],
    directives: {money3: Money3Directive},
    components: {
        Modal,
        Select2,
        editUserComponent,
        pageHeader,
        createUsers,
        PaginationComponent
    },

    setup() {
        const toast = useToast();
        const {t} = useI18n();
        return {t, toast}
    },

    data() {
        return {
            ajaxPerson: {
                width: '100%',
                language: {
                    inputTooShort: function () {
                        return "Por favor, insira ao menos 1 caracteres para pesquisar.";
                    },
                    noResults: function () {
                        return "Nenhum resultado encontrado!";
                    }
                },
                minimumInputLength: 1,
                ajax: {
                    url: `${process.env.VUE_APP_BACK_END}/api/v1/clientes/list?onlyRealState=true`,
                    dataType: "json",
                    headers: {
                        'authorization': `Bearer ${localStorage.getItem('access_token')}`,
                        'company-id': localStorage.getItem('companyId'),
                    },
                    data: params => {
                        return {
                            term: params.term,
                        };
                    },
                    processResults: function (data) {
                        if (!(data.validations)) {
                            data.unshift({id: 0, text: "Nenhum"});
                            return {
                                results: data.map((i) => ({id: i.id, text: i.text}))
                            };
                        } else {
                            return {results: {id: 0, text: "Nenhum"}};
                        }
                    },
                    cache: true
                },
                initSelection: function (element, callback) {
                    callback({id: 1, text: 'initSelection test'});
                },
            },
            filters: {},
            name: null,
            items: null,
            profiles: [],
            filter: null,
            userKey: null,
            allotment: null,
            userEdit: {},
            userCreate: null,
            modalTitle: null,
            companyName: null,
            userAllotment: {},
            hasAllotment: null,
            modalEdit: false,
            userCompanyGroup: {},
            hasShowPermission: false,
            hasListPermission: false,
            hasCreatePermission: false,
            hasDeletePermission: false,
            hasUpdatePermission: false,
            companies: JSON.parse(localStorage.getItem('companies')),
            configBrokerage: [],
            wichModal: null,
            user: {},
        }
    },

    mounted() {
        if (!this.validatePermissions('User:index')) {
            this.$router.push({name: 'Home'});
            this.toast.error(this.t('GENERAL.MSG.YOU_DONT_HAVE_PERMISSON'), {timeout: false});
        } else {
            this.$store.commit('changeLoading', true);

            this.index()
            this.indexGroup();
            this.indexCompany();
        }
    },

    methods: {
        filtering() {
            this.modalEdit = false;
            this.userCreate = null;
            this.wichModal = 1;
            this.modalTitle = "Filtro";
        },
        closeFilter() {
            this.filters = {};
            document.getElementById('closeX')?.click();
            this.index(this.filters);
            this.wichModal = null;
        },
        filterIndex() {
            if (this.filters.typeUser === 'selecione') {
                delete this.filters.typeUser;
            }
            document.getElementById('closeX')?.click();
            this.index(this.filters);
        },
        resetConfigBrokerage(user, type) {
            user.brokerage_value = 0;
            user.brokerage_is_percentage = type;
            this.setConfigBrokerage(type, user.id);
        },
        closeModalEdit() {
            this.wichModal = null;
            this.userCreate = null;
            this.modalEdit = false;
            this.userEdit = {};
            this.modalTitle = '';
        },
        setModalEditUser(user) {
            let div = document.createElement('div');
            div.setAttribute('data-toggle', 'modal');
            div.setAttribute('data-target', '#customModalTwo');
            let body = document.getElementsByTagName('body')[0];
            body.appendChild(div);
            setTimeout(function () {
                div.click();
                div.remove();
            }, 100);
            this.userCreate = null;
            this.modalEdit = true;
            this.userEdit = user;
            this.modalTitle = 'Editar Usuário';
        },
        copyLink(id) {
            let link = window.location.origin + '/cliente-publico/' + localStorage.getItem('companyId') + '/' + id;
            const inputTemporario = document.createElement('input');
            inputTemporario.value = link;
            document.body.appendChild(inputTemporario);
            inputTemporario.select();
            document.execCommand('copy');
            document.body.removeChild(inputTemporario);
            this.toast.success('Link copiado com sucesso!')
        },
        setConfigBrokerage(type, id) {
            if (type) {
                this.configBrokerage[id].max = 100;
                this.configBrokerage[id].prefix = '';
                this.configBrokerage[id].decimal = '.';
                this.configBrokerage[id].thousands = '';
                this.configBrokerage[id].suffix = '%';
                this.configBrokerage[id].disabled = false;
                this.configBrokerage[id].minimumNumberOfCharacters = 0;
            } else {
                this.configBrokerage[id].suffix = '';
                this.configBrokerage[id].decimal = ',';
                this.configBrokerage[id].thousands = '.';
                this.configBrokerage[id].prefix = 'R$ ';
                this.configBrokerage[id].disabled = false;
                this.configBrokerage[id].max = 100000000000;
                this.configBrokerage[id].minimumNumberOfCharacters = 1;
            }
        },
        validateMoney3(value) {
            if (value % 2 == 0) {
                return value * 100;
            } else {
                return value;
            }
        },
        configBrokerageType(user, type) {
            let id = user.id;
            if (this.configBrokerage[id]) {
                return true;
            }
            if (type) {
                this.configBrokerage[id] = {};
                this.configBrokerage[id].max = 100;
                this.configBrokerage[id].prefix = '';
                this.configBrokerage[id].decimal = '.';
                this.configBrokerage[id].thousands = '';
                this.configBrokerage[id].suffix = '%';
                this.configBrokerage[id].disabled = false;
                this.configBrokerage[id].minimumNumberOfCharacters = 0;
            } else {
                this.configBrokerage[id] = {};
                this.configBrokerage[id].suffix = '';
                this.configBrokerage[id].decimal = ',';
                this.configBrokerage[id].thousands = '.';
                this.configBrokerage[id].prefix = 'R$ ';
                this.configBrokerage[id].disabled = false;
                this.configBrokerage[id].max = 100000000000;
                this.configBrokerage[id].minimumNumberOfCharacters = 1;
            }
            return this.configBrokerage[id];
        },
        indexCompany() {
            this.$store.commit('changeLoading', true);
            UserAllotment.listCompanyAllotment().then(resp => {
                this.companies = resp.data;
                if (resp.data.length >= 1) {
                    this.hasAllotment = resp.data[0].allotments[0] ? true : false;
                    this.$store.commit('changeLoading', false);
                }
            }).catch(error => {
                this.$store.commit('changeLoading', false);
                this.errorMsg(error)
            });
        },

        indexGroup() {
            Group.list().then(resp => {
                this.profiles = resp.data;

            }).catch(error => {
                this.$store.commit('changeLoading', false);
                this.errorMsg(error)
            });
        },

        setRegisterModal() {
            this.wichModal = null;
            this.modalEdit = false;
            if (this.hasAllotment) {

                this.modalTitle = 'USERS.MODAL_TITLE.ADD';

            } else {
                this.toast.error(this.t('GENERAL.MSG.DONT_HAVE_ALLOTMENT'), {timeout: false});
            }

            this.userCreate = {
                user: {
                    name: null,
                    phones: [],
                    email: null,
                    creci: null,
                    active: true,
                    password: null,
                    scope: "",
                },
                userCompaniesGroups: []
            }
        },

        index(filter = null, page = 1) {
            this.$store.commit('changeLoading', true);

            this.companyName = this.companies.find(c => c.id == localStorage.getItem('companyId')).text;

            User.showByCompany(localStorage.getItem('companyId'), filter, page).then(resp => {
                this.items = resp.data;
                this.$store.commit('changeLoading', false);
            }).catch(error => {
                this.$store.commit('changeLoading', false);
                this.errorMsg(error);
            });
        },

        open(user, isReload = false, add = false) {
            if (!add) {
                user.open = !user.open ? true : false;
            }

            if (user.open && !user.userAllotments || isReload) {
                user.loading = true;

                UserAllotment.getAllotmentsById(user.id).then(resp => {

                    let arrayOfId = [];
                    if (resp.data.validations) {
                        user.userAllotments = [];
                    } else {
                        resp.data.forEach(item => {
                            arrayOfId.push(item.allotment_id);
                            if (item.brokerage_value) {
                                item.brokerage_value = parseFloat(item.brokerage_value).toFixed(2);
                            }
                        });
                        user.userAllotments = resp.data;
                    }

                    Allotments.listForUsers(true, true).then(re => {
                        let allotments = [];
                        if (!re.data.validations) {
                            re.data.forEach(allotment => {
                                let teste = arrayOfId.find(id => id == allotment.id);
                                if (teste) {
                                    let allotUser = user.userAllotments.find((i) => i.allotment_id === allotment.id);
                                    if (allotUser) {
                                        allotments.push(allotUser)
                                    } else {
                                        allotments.push({id: allotment.id, name: allotment.text});
                                    }
                                } else {
                                    allotments.push({id: allotment.id, name: allotment.text});
                                }
                            });
                        } else {
                            user.dontHaveAllotment = true;
                        }
                        user.userAllotments = allotments;
                        user.loading = false;
                    })
                }).catch(error => {
                    user.loading = false;
                    this.errorMsg(error);
                });
            }
        },

        onOffNotificatonsUserAllotment(userAllotment, userId) {
            this.$store.commit('changeLoading', true);
            userAllotment.user_id = userId;
            let brokerageValueFinal;
            if (userAllotment.brokerage_is_percentage) {
                brokerageValueFinal = userAllotment.brokerage_value.replaceAll("%", "");
                brokerageValueFinal = brokerageValueFinal.replaceAll(",", ".");
            } else {
                brokerageValueFinal = userAllotment.brokerage_value.replaceAll("R$ ", "");
                brokerageValueFinal = brokerageValueFinal.replaceAll(".", "");
                brokerageValueFinal = brokerageValueFinal.replaceAll(",", ".");
            }
            userAllotment.brokerage_value = brokerageValueFinal;
            UserAllotment.update(userAllotment).then(() => {
                this.$store.commit('changeLoading', false);
                if (userAllotment.notify) {
                    this.toast.success(this.t('USERS.NOTIFY_ALLOWED'));
                } else {
                    this.toast.success(this.t('USERS.NOTIFY_DENIED'));
                }
            }).catch(error => {
                this.$store.commit('changeLoading', false);
                this.errorMsg(error);
            });
        },

        updateUserAllotment(userAllotment, userId, isSwitch = false) {
            this.$store.commit('changeLoading', true);
            userAllotment.user_id = userId;

            let brokerageValueFinal;
            if (userAllotment.brokerage_is_percentage) {
                brokerageValueFinal = userAllotment.brokerage_value.replaceAll("%", "");
                brokerageValueFinal = brokerageValueFinal.replaceAll(",", ".");
            } else {
                brokerageValueFinal = userAllotment.brokerage_value.replaceAll("R$ ", "");
                brokerageValueFinal = brokerageValueFinal.replaceAll(".", "");
                brokerageValueFinal = brokerageValueFinal.replaceAll(",", ".");
            }
            userAllotment.brokerage_value = brokerageValueFinal;
            if (userAllotment.real_estate_agency_id === '0') {
                userAllotment.real_estate_agency_id = null;
                userAllotment.real_estate_agency_name = null;
            }
            UserAllotment.update(userAllotment).then(() => {
                this.$store.commit('changeLoading', false);
                if (isSwitch && userAllotment.active) {
                    this.toast.success(this.t('GENERAL.MSG.ACCESS_GRANTED_WITH_SUCCESS'));
                } else if (isSwitch && !userAllotment.active) {
                    this.toast.success(this.t('GENERAL.MSG.ACCESS_DENIED_WITH_SUCCESS'));
                } else {
                    this.toast.success('Usuário Atualizado');
                }
            }).catch(error => {
                this.$store.commit('changeLoading', false);
                this.errorMsg(error);
            });
        },

        updateUserCompanhie(userCompany, isSwitch = false, isCategory) {
            this.$store.commit('changeLoading', true);

            if (isCategory) {
                userCompany = {
                    user_id: userCompany.id,
                    active: userCompany.active,
                    company_id: userCompany.company_id,
                    group_id: userCompany.group_id,
                    id: userCompany.users_companies_groups_id
                }

                UserAllotment.updatePermission(userCompany).then(() => {
                    UserCompany.update(userCompany).then(() => {
                        this.$store.commit('changeLoading', false);
                        if (isSwitch && userCompany.active) {
                            this.toast.success(this.t('GENERAL.MSG.ACCESS_GRANTED_WITH_SUCCESS'));
                        } else if (isSwitch && !userCompany.active) {
                            this.toast.success(this.t('GENERAL.MSG.ACCESS_DENIED_WITH_SUCCESS'));
                        } else {
                            this.toast.success(this.t('GENERAL.MSG.GRUOP_UPDATED_SUCCESS'));
                        }
                    }).catch((err) => {
                        this.$store.commit('changeLoading', false);
                        this.errorMsg(err);
                    })
                }).catch((err) => {
                    this.$store.commit('changeLoading', false);

                    if (err.response.status === 401) {
                        this.toast.error(this.t(err.response.data.message), {timeout: false});
                    } else {
                        this.toast.error(this.t(err.response.data.message), {timeout: false});
                    }
                })
            } else {
                userCompany.active = this.user.active;
                UserCompany.update(userCompany).then(() => {
                    this.$store.commit('changeLoading', false);
                    if (isSwitch && userCompany.active) {
                        this.toast.success(this.t('GENERAL.MSG.ACCESS_GRANTED_WITH_SUCCESS'));
                    } else if (isSwitch && !userCompany.active) {
                        this.toast.success(this.t('GENERAL.MSG.ACCESS_DENIED_WITH_SUCCESS'));
                    } else {
                        this.toast.success(this.t('GENERAL.MSG.GRUOP_UPDATED_SUCCESS'));
                    }
                    this.items.data[this.userKey.key].active = userCompany.active;
                    document.getElementById('closeModal').click();
                }).catch(error => {
                    document.getElementById('closeModal').click();
                    this.$store.commit('changeLoading', false);
                    this.errorMsg(error);
                });
            }
        },

        setAddUserAllotmentModal(userAllotment, user, group_id, key, allotmentKey) {
            this.userAllotment =
                    {
                        active: true,
                        user_id: user.id,
                        max_number_reserves: 2,
                        allotment_id: userAllotment.id,
                        is_realtor: group_id == 1 ? false : true
                    };

            this.name = user.name;
            this.allotment = userAllotment.name;
            this.modalTitle = 'USERS.MODAL_TITLE.REALY_WHANT_GRANT_ACCESS_ALLOTMENT';
            this.userKey = {key: key, allotmentKey: allotmentKey};

            document.getElementById('addUserModal').click();
        },

        addUserAllotment() {
            this.$store.commit('changeLoading', true);
            this.items[this.userKey.key] = false;

            UserAllotment.create(this.userAllotment, this.userAllotment.allotment_id).then((resp) => {

                this.$store.commit('changeLoading', false);
                this.toast.success(this.t('GENERAL.MSG.ACCESS_GRANTED_WITH_SUCCESS'));

                let id = resp.data.user_id;
                document.getElementById('closeModal').click();

                this.items.data.forEach((item) => {
                    if (item.id == id) {
                        this.open(item, true, true);
                    }
                })
            }).catch(error => {
                this.errorMsg(error);
                this.$store.commit('changeLoading', false);
            });
        },

        setCompanyModal(user, key) {
            this.user = JSON.parse(JSON.stringify(user));
            this.user.active = !this.user.active;
            this.name = user.name;
            this.allotment = null;
            this.userKey = {key: key, allotmentKey: null};
            this.userCompanyGroup = {
                user_id: user.id,
                active: user.active,
                group_id: user.group_id,
                company_id: user.company_id,
                id: user.users_companies_groups_id,
            }
            this.modalTitle = `Realmente deseja ${this.user.active ? 'Ativar' : 'Inativar'} este usuario?`;

            //  document.getElementById("close_modal").style.display = 'none';
            document.getElementById('addUserModal').click();
        },

        closeModal() {
            if (this.allotment) {
                this.items.data[this.userKey.key].userAllotments[this.userKey.allotmentKey].active = false;
            } else {
                if (this.user?.id) {
                    this.items.data?.forEach((i, k) => {
                        if (i.id === this.user.id) {
                            this.user.active = !this.user.active;
                            this.items.data[k] = this.user;
                        }
                    })
                    this.user = {};
                } else {
                    this.items.data[this.userKey.key].active = !this.items[this.userKey.key].active;
                }
            }
        },

        paddingGroup() {
            return this.widthPhone() ? {
                'padding-left': '1rem',
                'padding-right': '1rem',
                'margin-top': '0.5rem',
                'margin-bottom': '0.5rem'
            } : {'padding-right': '1.5rem'};
        },

        search(filter) {
            this.filter = filter;
            this.index(filter);
        }
    },
}
</script>

<style>
.paddin {
    padding-left: 1rem !important;
    padding-top: 0.75rem !important;
    padding-bottom: 0.5rem !important;
}

.button-center {
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #333333;
    border-radius: 50px;
    font-size: 160%;
}

.pointer {
    cursor: pointer;
}

.user-name {
    color: #000;
    padding: 0rem 0rem 0rem 1.5rem;
    font: 400 0.8rem 'Open Sans', sans-serif;
}

.status-active {
    padding: 0.5rem 1.5rem 0rem 1.5rem;
    font-size: 0.7rem !important;
}

.color-grey {
    color: #727272;
}

.btn-holver {
    border: none;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    transition-duration: 0.4s;
}

.bg-btn {
    background-color: #f2f4f9 !important;
    color: black !important;
}

.bg-btn:hover {
    background-color: #0e5e4e !important;
    color: white !important;
}

.table-settings {
    padding-top: 0.6rem !important;
    padding-right: 1rem !important;
    background-color: white !important;
}

.fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active em versões anteriores a 2.1.8 */
{
    opacity: 0;
}

.more-info {
    padding-left: 2rem !important;
    background-color: rgb(245, 245, 245);
}

.color-black {
    color: #000;
}

.active-general {
    max-width: 75px;
    border-radius: 1rem;
    color: white;
}

.space-between {
    padding-bottom: 8px;
    align-items: center;
    justify-content: space-between;
}

.align-items {
    align-items: center;
}

.font-seven {
    font-size: 0.7rem !important;
}

.ptb {
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.hearder-custom {
    color: #000 !important;
    background-color: white !important;
}

.title-size {
    font-size: 1rem !important;
}

.close-custom {
    color: #000 !important;
}

.right {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end !important;
    border-bottom-right-radius: calc(.3rem - 1px);
    border-bottom-left-radius: calc(.3rem - 1px);
}

.width-buttons {
    width: 30%;
}

.first-box {
    box-shadow: inset 0px 1px 5px rgb(139 139 139 / 35%) !important;
}

.second-box {
    box-shadow: inset 0px -1px 5px rgb(139 139 139 / 35%) !important;
}

.loading-box {
    padding-bottom: 0.1rem;
    box-shadow: inset 0px 0px 5px rgb(139 139 139 / 35%) !important;
}
</style>
